
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

@font-face {
  font-family: "Nor";
  src: url('./assets/fonts/Nor/NOIRetBLANC-Regular.ttf');
}

@font-face {
  font-family: "PS-Paradizo";
  src: url('./assets/fonts/PS-Paradizo/ps-paradizo-regular.otf');
}


$brand-red: #AE0C06;


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  font-family: 'Manrope', sans-serif;
  scroll-behavior: smooth;
  
}

body{
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  overflow-y: auto;
  background: #FFF9EF !important;
}

section{
  height: auto;
  width: 100vw;
  margin: 16rem 0;
}

.header{
  font-size: 5rem;
  font-weight: 500;
  font-family: 'PS-Paradizo',sans-serif;
}


.subheader{
  font-size: 1.75rem;
  font-weight: 800;
  font-family: 'Manrope',sans-serif;
}

.text{
  font-size: 1rem;
  font-weight: 300;
  font-family: 'Manrope', sans-serif;
}

.red-text{
  color: $brand-red;
}

.btn-primary{
  border: none !important;
  color: $brand-red !important;
  background: transparent !important;
  font-weight: 800 !important;
  font-family: 'Manrope', sans-serif;
}


@media screen and (max-width: 1200px) {
  section{
      height: auto !important; 
      margin: 1rem 0 !important;
  }

  .header{
    font-size: 4rem;
    font-weight: 500;
    font-family: 'PS-Paradizo',sans-serif;
  }

  .nav-div{
    padding-bottom: 120px;
  }

}


@media only screen and (max-width: 768px) and (min-width: 320px) {
  .nav-mob-hide{
      display: none !important;
  }

  .nav-div{
    padding-bottom: 50px;
  }
}

@media only screen and (min-width: 768px) {
  .nav-desktop-hide{
      display: none !important;
  }
  .nav-div{
    padding-top: 0px;
  }

}


.loader_container{
  justify-content: center;
  text-align: center;
  height: 100vh;
  z-index: 999;
  background: #FFF9EF;
}

.loader {
  width: 48px;
  height: 48px;
  border: 2px solid #FFF;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after,
.loader::before {
  content: '';  
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  background: #FF3D00;
  width: 6px;
  height: 6px;
  transform: translate(150%, 150%);
  border-radius: 50%;
}
.loader::before {
  left: auto;
  top: auto;
  right: 0;
  bottom: 0;
  transform: translate(-150%, -150%);
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 